<template>
  <div>
    <MiniFooter>
      <template v-slot:left>
        <li class="list-inline-item">
          <router-link to="/">Privacy Policy</router-link>
        </li>
        <li class="list-inline-item">
          <router-link to="/">Terms of Use</router-link>
        </li>
      </template>
      <template v-slot:right>
        Copyright {{ year }} <router-link to="/">{{ appName }}</router-link> All Rights Reserved.
      </template>
    </MiniFooter>
  </div>
</template>
<script>
import MiniFooter from '../../components/core/footer/MiniFooter'
import { APPNAME } from '../../config/pluginInit'
export default {
  name: 'LayoutFooter',
  components: {
    MiniFooter
  },
  mounted () {
  },
  data () {
    return {
      appName: APPNAME,
      year: new Date().getFullYear()
    }
  }
}
</script>
