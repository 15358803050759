<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="iq-menu-bt d-flex align-items-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><i class="las la-bars"></i></div>
          </div>
          <div class="iq-navbar-logo d-flex justify-content-between">
            <router-link to="/" class="header-logo">
              <img
                src="../../../assets/images/logo.png"
                class="img-fluid rounded-normal"
                alt=""
              />
              <div class="logo-title">
                <span class="text-primary text-uppercase">Urbanlibrary</span>
              </div>
            </router-link>
          </div>
        </div>
        <div class="navbar-breadcrumb">
          <h5 class="mb-0">{{ this.$route.meta.name }}</h5>
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ this.$route.meta.name }}
              </li>
            </ul>
          </nav>
        </div>
        <div class="iq-search-bar">
          <form action="#" class="searchbox" >
            <!-- <input type="text" class="text search-input" placeholder="Search Here...">
            <a class="search-link" href="#"><i class="ri-search-line"></i></a> -->

            <v-select
              v-model="selected"
              :options="options"
              :loading="loading"
              @search="search"
              label="title"
              placeholder="Search Here..."
              :filterable="false"
              :clearable="false"
              id="mySelect"
              class="search-input text w-100 border-0"
            >
              <template slot="option" slot-scope="option">
                <b-container
                  @click="goToSelected(option)"
                  :title="option.title"
                  class="border-bottom py-2"
                >
                  <b-row>
                    <b-col>
                      <img :src="option.cover_url" class="book-img" />
                    </b-col>
                    <b-col>
                      <p
                        class="mb-0 font-size-14 font-weight-bold truncated-search subtitle"
                      >
                        {{ option.title }}
                      </p>
                      <small class="">
                        <span
                          v-for="authorDetail in option.author"
                          :key="authorDetail.slug"
                          class="text-dark"
                          >{{ authorDetail.name }} ,</span
                        >
                      </small>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
            </v-select>
            <a class="search-link" href="#"><i class="ri-search-line"></i></a>
          </form>
        </div>
        <b-navbar-toggle target="nav-collapse" class="mr-2">
          <i class="ri-menu-3-line"></i>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <slot name="responsiveRight" />
        </b-collapse>
        <slot name="right" />
      </nav>
    </div>
  </div>
  <!-- TOP Nav Bar END -->
</template>
<script>
import { APPNAME } from '../../../config/pluginInit'
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'DefaultNavBar',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'shop.home' }) },
    logo: { type: String, default: require('../../../assets/images/logo.png') },
    horizontal: { type: Boolean, default: false },
    items: { type: Array },
    sidebarGroupTitle: { type: Boolean, default: true }
  },
  mounted () {
    if (!this.horizontal) {
      document.addEventListener('click', this.closeSearch, true)
    }
  },
  components: {},
  computed: {
    ...mapGetters({
      bookmark: 'Setting/bookmarkState'
    })
  },
  watch: {
    // selected () {
    //   //  go to the book page with this slug
    //   this.$router.push(`/book/${this.selected.slug}`)
    //   // this.$router.push({ name: 'book', params: { bookSlug: this.selected.slug } })
    // }
  },
  data () {
    return {
      selected: '',
      options: [],
      loading: false,
      errorInSearch: null,
      appName: APPNAME,
      globalSearch: '',
      showSearch: false,
      showMenu: false
    }
  },
  methods: {
    goToSelected (selected) {
      this.$router.push(`/book/${selected.slug}`)
    },
    async search (query) {
      // try {
      //   const response = await axios.get(`books/simplesearch?q=${query}`)
      //   console.log(response)
      //   this.options = response.data
      // } catch (error) {
      //   console.error(error)
      // }
      if (query.length < 3) return
      this.loading = true
      axios
        .get(`books/simplesearch?q=${query}`)
        .then((response) => {
          this.options = response.data
        })
        .catch((_error) => {
          this.errorInSearch = true
        })
      this.loading = false
    },
    miniSidebar () {
      this.$emit('toggle')
    },
    openSearch () {
      this.showSearch = true
      this.showMenu = 'iq-show'
      this.globalSearch = ''
      if (document.getElementById('searchbox-datalink') !== null) {
        document
          .getElementById('searchbox-datalink')
          .classList.add('show-data')
      }
    },
    closeSearch (event) {
      let classList = event.target.classList
      if (
        !classList.contains('searchbox') &&
        !classList.contains('search-input')
      ) {
        this.removeClass()
      }
    },
    removeClass () {
      this.showSearch = false
      this.showMenu = ''
      this.globalSearch = ''
      if (
        document.getElementById('searchbox-datalink') !== null &&
        document.getElementById('searchbox-datalink') !== undefined
      ) {
        document
          .getElementById('searchbox-datalink')
          .classList.remove('show-data')
      }
    }
  }
}
</script>

<style>
.collapse-menu {
  @media (min-width: 1300px) {
    display: none;
  }
}
.iq-sidebar-menu .iq-menu.hover-menu {
  display: flex;
  @media (max-width: 1299px) {
    display: none !important;
  }
}

.search-input {
  width: 500px !important;
  font-size: 16px;
}

.book-img {
  height: 40px;
  width: 40px;
}

.v-select {
  width: 1000px !important;
}

#mySelect .v-select .dropdown-toggle {
   border: none;
}
#mySelect .v-select .dropdown-toggle .actions {
   display: none;
}

.search-input .vs__dropdown-toggle {
  border: none;
}

#vs1__combobox {
  border: 0px !important;
}
</style>
